import { Analytics } from './Analytics'

export const AnalyticsConsoleLogger: Analytics = {
  createIdentity: (userId) => l('createIdentity', userId),
  pageView: (page) => l('pageView', page),
  trackEvent: ({ name, props }) => l('trackEvent', name, props),
  signOutUser: () => l('signOutUser'),
  setUserProperties: (userId) => l('setUserProperties', userId),
  setSuperProperties: (props) => l('setSuperProperties', props),
  registerExternalLinkClickListener: () => l('registerExternalLinkClickListener'),
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-console
const l = (name: string, ...inputs: any[]) => console.log('analyticsConsoleLogger', name, inputs)
