/* eslint-disable react/jsx-no-useless-fragment */
import React, { FunctionComponent, useContext, useEffect } from 'react'
import { Analytics, AnalyticsConsoleLogger } from '@tumelo/analytics'
import { useRouter } from '../router/useRouter'
import { useAppSelector } from '../store'
import { isValueState } from '../payload'
import { selectHabitat } from '../features/config/selectors'
import { selectInvestor } from '../features/investor/investorSelector'
import { buildEvent } from './eventBuilders'

export const AnalyticsContext = React.createContext<Analytics>(AnalyticsConsoleLogger)

export const useAnalytics = () => {
  return useContext(AnalyticsContext)
}

const useExplicitPageView = (analytics: Analytics, page: string) =>
  React.useEffect(() => {
    analytics.pageView(page)
  }, [analytics, page])

export const ExplicitPageView: FunctionComponent<React.PropsWithChildren<{ page: string }>> = ({ page, children }) => {
  const analytics = useAnalytics()
  useExplicitPageView(analytics, page)
  return <>{children}</>
}

const usePageViewFromLocationPathname = () => {
  const { location } = useRouter()
  const analytics = useAnalytics()
  useExplicitPageView(analytics, location.pathname)
}

/**
 * Tracks the current page being visited by the user using React Router and Google Analytics.
 */
export const PageViewFromLocationPathname: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  usePageViewFromLocationPathname()
  return <>{children}</>
}

/**
 * Sets the user tag
 */
export const SetUserProperties: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const investor = useAppSelector(selectInvestor)
  const habitat = useAppSelector(selectHabitat)
  const analytics = useAnalytics()
  useEffect(() => {
    if (isValueState(investor))
      analytics.setUserProperties(investor.sub ?? '', {
        investorId: investor.investorId,
        habitat,
      })
  }, [investor, habitat, analytics])
  return <>{children}</>
}

export const TrackLoadPWA: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { trackEvent } = useAnalytics()
  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      trackEvent(buildEvent.loadPWA('installed'))
    } else {
      trackEvent(buildEvent.loadPWA('browser'))
    }
  })
  return <>{children}</>
}
