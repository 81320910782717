import React from 'react';
import styled from 'styled-components';
import { screen } from '../_utils/mediaQueries';
import { CommonMarkRenderer } from './CommonMark/CommonMarkRenderer';
export const MarkdownView = ({ markdown }) => {
    const sanitizedHTML = new CommonMarkRenderer().renderHtml(markdown);
    // eslint-disable-next-line react/no-danger
    return React.createElement(StyledMarkdown, { dangerouslySetInnerHTML: { __html: sanitizedHTML } });
};
export const StyledMarkdown = styled.span `
  p {
    color: ${({ theme }) => theme.colors.dark};
    font-size: ${({ theme }) => theme.fontSizes.body1.mobile};
    line-height: ${({ theme }) => theme.lineHeights.body1.mobile};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin-block-start: 0;
    margin-block-end: 0.8rem;

    @media ${screen.mediumUp} {
      font-size: ${({ theme }) => theme.fontSizes.body1.default};
      line-height: ${({ theme }) => theme.lineHeights.body1.default};
      margin-block-end: 1.6rem;
    }
  }

  h1 {
    color: ${({ theme }) => theme.colors.dark};
    font-size: ${({ theme }) => theme.fontSizes.h1.mobile};
    line-height: 3.2rem;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-block-start: 0;
    margin-block-end: 2.4rem;

    @media ${screen.mediumUp} {
      font-size: ${({ theme }) => theme.fontSizes.h1.default};
      line-height: 4.8rem;
      margin-block-end: 4rem;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.dark};
    font-size: ${({ theme }) => theme.fontSizes.h2.mobile};
    line-height: 3.2rem;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-block-start: 0;
    margin-block-end: 1.6rem;

    @media ${screen.mediumUp} {
      font-size: ${({ theme }) => theme.fontSizes.h2.default};
      line-height: 3.8rem;
      margin-block-end: 2.4rem;
    }
  }

  h3 {
    color: ${({ theme }) => theme.colors.dark};
    font-size: ${({ theme }) => theme.fontSizes.h3.mobile};
    line-height: 2.8rem;
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    margin-block-start: 0;
    margin-block-end: 1.6rem;

    @media ${screen.mediumUp} {
      font-size: ${({ theme }) => theme.fontSizes.h3.default};
      line-height: 3.2rem;
      margin-block-end: 2.4rem;
    }
  }

  h4 {
    color: ${({ theme }) => theme.colors.dark};
    font-size: ${({ theme }) => theme.fontSizes.h4.mobile};
    line-height: 2.4rem;
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    margin-block-start: 0;
    margin-block-end: 1.6rem;

    @media ${screen.mediumUp} {
      font-size: ${({ theme }) => theme.fontSizes.h4.default};
      line-height: 2.8rem;
    }
  }

  a {
    text-decoration: underline;
  }

  ul {
    line-height: 3.2rem;
    margin-block-end: 1.6rem;
    margin-left: 2rem;
    list-style-type: disc;
    li {
      margin-block-end: 0.8rem;
    }
  }
`;
